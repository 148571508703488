/* .toplinks a {

    background-color: black;
    border: none;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 4px 2px;
    border-radius: 50%;

    text-decoration: none;
    width: 30px;
    text-align: center;
}
.toplinks a:hover {
    background-color: blue;
} */

.toplinks a, .toplinks button {
    margin-right: 20px;
    width: 60px;
}

@media screen and (max-width: 575px) {
    .toplinks {
        flex-direction: row;
    }
  }
