.electoralvotesRow {
    font-weight: bold;
    /*
    font-size: 20px;
    */
}

.leftThirdParty {
    color: green;
    text-align: center;
}

.democrats {
    color: blue;
    text-align: center;
}

.ties {
    color: grey;
    text-align: center;
}

.republicans {
    color: red;
    text-align: center;
}

.rightThirdParty {
    color: orange;
    text-align: center;
}