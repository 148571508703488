.mapsidebar {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 10px;
    gap: 10px 20px; /* row-gap column gap */
    row-gap: 10px;
    column-gap: 20px;
}

.mapsidebar div {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 10px;
    gap: 10px 20px; /* row-gap column gap */
    row-gap: 10px;
    column-gap: 20px;

    border: 1px solid green;
    width: 50px;
    height: 50px;
    color: white;
}

@media screen and (max-width: 1199px) { 
    .mapcontainer {
        flex-direction: column;
    }
    .mapsidebar {
        flex-direction: row;
    }
  }

.darkblue {
    background-color: darkblue;
}

.blue {
    background-color: blue;
}

.lightblue {
    background-color: lightblue;
}

.darkgreen {
    background-color: darkgreen;
}

.green {
    background-color: green;
}

.palegreen {
    background-color: palegreen;
}

.darkred {
    background-color: darkred;
}

.red {
    background-color: red;
}

.lightpink {
    background-color: lightpink;
}

.darkorange {
    background-color: darkorange;
}

.orange {
    background-color: orange;
}

.lightsalmon {
    background-color: lightsalmon;
}
.grey {
    background-color: grey;
}